import styled, { css } from 'styled-components';

export const WrapperStyle = styled.section`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const columns = (template: string, $disableMobileBehavior: boolean): any => css`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: ${template};

  ${!$disableMobileBehavior &&
  css`
    @media (max-width: 991px) {
      grid-template-columns: 1fr;a
    } 
  `}
`;

interface IRowStyleProps {
  $cols?: string;
  $verticalAlignment?: 'top' | 'bottom' | 'center';
  $horizontalAlignment?: 'left' | 'right' | 'center';
  $disableMobileBehavior?: boolean;
}
export const RowStyle = styled.div<IRowStyleProps>`
  ${({ $cols, $disableMobileBehavior }) => columns($cols || '1fr', $disableMobileBehavior || false)}

  ${({ $verticalAlignment }) => {
    let alignment = 'center';
    if ($verticalAlignment === 'top') alignment = 'flex-start';
    if ($verticalAlignment === 'bottom') alignment = 'flex-end';

    return css`
      align-items: ${alignment};
    `;
  }}
  
  ${({ $horizontalAlignment }) => {
    let alignment = 'center';
    if ($horizontalAlignment === 'left') alignment = 'flex-start';
    if ($horizontalAlignment === 'right') alignment = 'flex-end';

    return css`
      justify-content: ${alignment};
    `;
  }}
`;
