/* eslint-disable no-nested-ternary */
import { darken } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

import { IButtonProps } from './types';

const defaultProps = (props: IButtonProps, theme: DefaultTheme): any => {
  const {
    buttonTheme: { background, front, borderRadius, borderColor },
    fontSize,
    iconPosition,
    isIconOnly,
    isSmall,
    disabledUppercase,
    hasBoxShadow,
    breakIconLine,
    lineHeight,
    iconSize,
  } = props;

  return css`
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;

    ${breakIconLine &&
    css`
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
    `}

    background: ${background};

    color: ${front};
    font-size: ${fontSize || 1}rem;
    line-height: ${lineHeight || 1}rem;
    font-family: ${theme.font.paragraph};
    font-weight: 600;

    padding: 1.3rem 2.3rem 1.1rem 2.3rem;
    border-width: 0;

    border-radius: ${borderRadius || '50px'};

    cursor: pointer;
    transition: 0.3s;

    ${!disabledUppercase &&
    css`
      text-transform: uppercase;
    `}

    ${hasBoxShadow &&
    css`
      box-shadow: 0px 3px 0px 1px rgba(0, 0, 0, 0.1);
    `}

    ${borderColor &&
    css`
      border: 1px solid ${borderColor};
    `};

    &:hover {
      background: ${darken(0.05, background)};

      a {
        text-decoration: none;
      }
    }

    &:disabled {
      opacity: 0.5;
    }

    svg {
      margin: ${isIconOnly || breakIconLine
        ? '0'
        : iconPosition === 'left'
          ? '0 0.6rem 0 0'
          : '0 0 0 0.6rem'};
      color: ${front};

      width: ${iconSize || 1.2}rem;
      height: ${iconSize || 1.2}rem;
    }

    span {
      font-size: inherit;
    }

    ${isSmall &&
    css`
      padding: 0.3rem 1.2rem;
      font-size: 0.8rem;
    `}
  `;
};

export const ButtonContainer = styled.button<IButtonProps>`
  ${({ theme, ...rest }) => defaultProps({ ...rest }, theme)}
`;

export const AContainer = styled.a<IButtonProps>`
  ${({ theme, ...rest }) => defaultProps({ ...rest }, theme)}

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
