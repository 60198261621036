import { WrapperStyle, RowStyle } from './styles';

interface IWrapperProps {
  children: React.ReactNode;
}

export const Wrapper: React.FC<IWrapperProps> = ({ children }) => (
  <WrapperStyle className="wrapper">{children}</WrapperStyle>
);

interface IRowProps {
  columns?: string;
  className?: string;
  verticalAlignment?: 'top' | 'bottom' | 'center';
  horizontalAlignment?: 'left' | 'right' | 'center';
  children: React.ReactNode;
  disableMobileBehavior?: boolean;
}

export const Row: React.FC<IRowProps> = ({
  columns,
  className,
  verticalAlignment = 'top',
  horizontalAlignment = 'left',
  disableMobileBehavior,
  children,
}) => (
  <RowStyle
    className={`row ${className || ''}`}
    $cols={columns}
    $verticalAlignment={verticalAlignment}
    $horizontalAlignment={horizontalAlignment}
    $disableMobileBehavior={disableMobileBehavior}
  >
    {children}
  </RowStyle>
);
