/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { useState, useCallback, useMemo, forwardRef, ChangeEvent } from 'react';

import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import masks from './masks';
import {
  Container,
  Error,
  Label,
  IconContainer,
  PasswordIconContainer,
  FieldRulesContainer,
  PreText,
  WrapperContainer,
} from './styles';
import { ICustomInputProps, IMaskProps } from './types';

const CustomInput = forwardRef<HTMLInputElement, ICustomInputProps>((props, ref) => {
  const {
    error,
    label = null,
    icon = false,
    type = 'text',
    disabled = false,
    fieldRules,
    preText,
    id,
    mask,
    value,
    className,
    onChange = () => {},
  } = props;

  const [inputType, setInputType] = useState<string>(type);
  const [inputOnFocus, setInputOnFocus] = useState<boolean>(false);

  const inputMask = useMemo((): IMaskProps => {
    if (!mask)
      return {
        middleware: (val: string) => val,
        maxLength: undefined,
      };

    return masks[mask];
  }, [mask]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<any>) => {
      if (!e) return;

      if (!mask) return onChange(e);

      const newEvent = e;
      newEvent.target.value = inputMask.middleware(e.target.value || '');

      return onChange(newEvent);
    },
    [inputMask, mask, onChange],
  );

  // Filtrando propriedades que não podem ser enviadas para o <input />
  const fieldProps = useMemo(() => {
    const { ...rest } = props;
    return rest;
  }, [props]);

  const field = useCallback(
    () => (
      <input
        {...fieldProps}
        ref={ref || null}
        value={inputMask.middleware(value || '')}
        maxLength={inputMask.maxLength || fieldProps.maxLength}
        type={inputType}
        disabled={disabled}
        onChange={handleOnChange}
      />
    ),
    [fieldProps, ref, inputMask, value, inputType, disabled, handleOnChange],
  );

  return (
    <WrapperContainer className={`custom-input-container ${className || ''}`}>
      {label && <Label>{label}</Label>}

      <Container
        $hasError={error}
        htmlFor={id}
        onMouseEnter={() => setInputOnFocus(true)}
        onMouseLeave={() => setInputOnFocus(false)}
        className="custom-input"
      >
        {icon || preText ? (
          <IconContainer $hasError={error}>
            <div>{preText ? <PreText>{preText}</PreText> : <FontAwesomeIcon icon={icon} />}</div>
            {field()}
          </IconContainer>
        ) : (
          <>{field()}</>
        )}

        {type === 'password' && (
          <PasswordIconContainer
            type="button"
            onClick={() => {
              inputType === 'password' ? setInputType('text') : setInputType('password');
            }}
          >
            <FontAwesomeIcon icon={inputType === 'password' ? faEye : faEyeSlash} />
          </PasswordIconContainer>
        )}

        {fieldRules && inputOnFocus && (
          <FieldRulesContainer $hasError={error}>
            <span>{fieldRules}</span>
          </FieldRulesContainer>
        )}
      </Container>

      {error && <Error>{error}</Error>}
    </WrapperContainer>
  );
});

export default CustomInput;
