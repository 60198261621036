import { forwardRef, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTheme } from 'hooks/useTheme';

import Loader from 'components/atom/Loader';

import { ButtonContainer, AContainer } from './styles';
import { getTheme } from './themes';
import { IButtonDefaultProps, IAProps } from './types';

const Button = forwardRef<any, IButtonDefaultProps>(
  (
    {
      theme = 'green',
      type = 'button',
      text,
      icon,
      iconPosition = 'left',
      fontSize = undefined,
      isLoading = false,
      isATag = false,
      isSmall = false,
      disabledUppercase = false,
      className,
      target,
      rel,
      href,
      disabled,
      ...rest
    },
    ref,
  ) => {
    const { theme: globalTheme } = useTheme();

    const themeProps = useMemo(() => getTheme(theme, globalTheme), [globalTheme, theme]);

    const Content = useMemo(
      (): React.ReactElement =>
        isLoading ? (
          <Loader color={themeProps.front} />
        ) : (
          <>
            {iconPosition === 'left' && icon && <FontAwesomeIcon icon={icon} />}
            {text}
            {iconPosition === 'right' && icon && <FontAwesomeIcon icon={icon} />}
          </>
        ),
      [icon, iconPosition, isLoading, text, themeProps.front],
    );

    const props = {
      fontSize,
      isIconOnly: text === undefined,
      iconPosition,
      buttonTheme: themeProps,
      className: `button button-${theme} ${className || ''}`,
      isSmall,
      disabledUppercase,
      disabled: disabled || isLoading,
    };

    const aProps: IAProps = {};
    if (href) aProps.href = href;
    if (target) aProps.target = target;
    if (rel) aProps.rel = rel;

    return isATag ? (
      <AContainer ref={ref} {...aProps} {...props}>
        {Content}
      </AContainer>
    ) : (
      <ButtonContainer ref={ref} type={type} {...rest} {...props}>
        {Content}
      </ButtonContainer>
    );
  },
);

export default Button;
