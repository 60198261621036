import { darken, lighten } from 'polished';
import styled from 'styled-components';

export const WrapperContainer = styled.div`
  margin-bottom: 1rem;
`;

interface IContainerProps {
  $hasError?: string;
}

export const Container = styled.label<IContainerProps>`
  display: flex;
  position: relative;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ $hasError, theme }) => ($hasError ? theme.colors.danger.color : theme.colors.border)};
  box-shadow: inset -3px 3px 0px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  input:hover,
  input:focus,
  input:active,
  input {
    flex: 1;

    width: 100%;
    height: 100%;

    background-color: transparent;
    border-width: 0 !important;

    font-family: ${({ theme }) => theme.font.paragraph};
    font-size: 1rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.paragraph};
    padding: 1.3rem 1.4rem 1.2rem 1.4rem;
    box-shadow: none;

    &#token {
      width: 15.1875rem;
      text-align: center;
      margin: auto;
      height: 3.0625rem;
      font-size: 1.5rem;
    }

    &:hover {
      border: 1px solid
        ${({ $hasError, theme }) => ($hasError ? theme.colors.danger.color : theme.colors.border)};
    }

    &::placeholder {
      font-size: 0.8rem;
      color: ${({ theme }) => lighten(0.1, theme.colors.paragraph)};
    }

    @media (max-width: 991px) {
      font-size: 1rem;
    }
  }
  input:disabled {
    font-weight: bold;
  }
`;

export const Error = styled.span.attrs({
  className: 'error',
})`
  display: block;

  color: ${({ theme }) => theme.colors.danger.color} !important;
  font-size: 0.7rem !important;
  font-weight: bold;
  text-align: left;

  padding: 0.3rem;
  margin-bottom: 0.5rem;
`;

export const PreText = styled.p`
  margin-bottom: 0;
  font-weight: bold;
`;

export const Label = styled.span`
  position: relative;

  display: block;
  margin-bottom: 0.5rem;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.colors.blue};
`;

export const PasswordIconContainer = styled.button`
  flex: 0.2;

  min-width: 1px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem;

  background: none;
  border-width: 0;

  svg {
    color: ${({ theme }) => theme.colors.dark.color};
    width: 1.2rem !important;
    height: 1.2rem !important;
    opacity: 0.8;
  }
`;

export const IconContainer = styled.div<IContainerProps>`
  position: relative;
  flex: 1;

  & > div {
    position: absolute;
    z-index: 10;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.25rem;

    svg {
      color: ${({ $hasError, theme }) =>
        $hasError ? theme.colors.danger.color : theme.colors.blue};
      width: 1.2rem !important;
    }
  }

  input:focus,
  input:active,
  input {
    padding-left: 3rem;
  }
`;

export const FieldRulesContainer = styled.div<IContainerProps>`
  position: absolute;
  z-index: 100;
  top: 100%;
  width: 100%;
  margin-top: -0.5rem;

  background-color: ${({ theme }) => theme.colors.light.color};

  padding: 0.5rem 1rem;

  p:last-of-type {
    margin-bottom: 0;
  }

  p,
  span,
  strong {
    color: ${({ theme }) => theme.colors.dark.color};

    font-size: 0.8rem;
    line-height: 1rem;
  }

  strong {
    font-weight: bold;
  }

  border: 1px solid
    ${({ $hasError, theme }) =>
      $hasError ? theme.colors.danger.color : darken(0.15, theme.colors.light.color)};
  border-top-width: 0;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
